.visible {
    visibility: visible;
    /*display: block;*/
}

.hidden {
    visibility: hidden;
    /*display: none;*/
}

#room-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#room-options rect {
    fill: #b6b6b6;
    stroke: #666;
    stroke-width: 1;
}

.house-div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.house-div rect,
.house-div path {
    filter:url(#shadow)
}

#svg-house-roof {
    animation-duration: 1s;
    animation-name: slide-down-roof;
}
#smoke {
    fill: #939393;
    stroke: #606060;
    stroke-width: 1;
    animation-duration: 6s;
    animation-name: smoke-up;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
}
#sun {
    fill: #fffedd;
    stroke: #eae284;
    stroke-width: 1;
    animation-duration: 6s;
    animation-name: sun-move;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    filter: url(#sun-shadow);
}

#svg-house-roof path {
    fill: #ffe5e5;
    stroke: #AAA;
    stroke-width: 1;
}
#svg-house-roof rect {
    fill: #626262;
}
#svg-house-roof #smoke {
    fill: #939393;
    stroke: #606060;
    stroke-width: 1;
}
#svg-house-second-floor {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: slide-down-2f;
}
#svg-house-second-floor rect {
    fill: #f5f5f5;
    stroke: #AAA;
    stroke-width: 1;
}
#svg-house-first-floor
/*#svg-house-kitchen*/
{
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: slide-down-1f;
}
#svg-house-first-floor rect,
#svg-house-kitchen rect {
    fill: #e8e8e8;
    stroke: #AAA;
    stroke-width: 1;
}
#svg-house-first-floor polyline {
    fill: #b4ffa1;
    stroke: #3ba92b;
    stroke-width: 1;
}
#svg-house-basement {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: slide-down-basement;
}
#svg-house-basement rect {
    fill: #b6b6b6;
    stroke: #666;
    stroke-width: 1;
}

@keyframes slide-down-roof {
    from {top: -80px;}
    to {top: 0;}
}
@keyframes smoke-up {
    to {
        cy: -30px;
        opacity: 0;
    }
}
@keyframes sun-move {
    from {
        cx: 300;
        cy: 20;
    }
    to {
        cx: 330;
        cy: 30;
    }
}
@keyframes slide-down-2f {
    from {top: -60px;}
    to {top: 0;}
}
@keyframes slide-down-1f {
    from {top: -40px;}
    to {top: 0;}
}
@keyframes slide-down-basement {
    from {top: 50px;}
    to {top: 0;}
}
