.destination-box {
  position: relative;
  width: 100%;
  height: 100px;
  margin: 40px 0;
  .MuiPaper-root {
    width: 100%;
    height: 100%;
  }
  .destination-name {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
    font-weight: bold;
  }
  .anreise {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .destination-nights {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .days {
    position: absolute;
    bottom: 35px;
    right: 10px;
  }
  .connection-line {
    position: absolute;
    bottom: -45px;
    height: 50px;
    width: 10px;
    background-color: #adadad;
    border: solid 1px #606060;
    left: 50%;
    z-index: -1;
  }
}

.destination-details-box {
  position: relative;
  width: 100%;
  height: 100px;
  margin: 40px 0;

  .MuiPaper-root {
    width: 100%;
    height: 100%;
  }

  .destination-name {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
    font-weight: bold;
  }

  .unterkunft {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .cost {
    position: absolute;
    top: 35px;
    right: 10px;
  }

  .days {
    position: absolute;
    top: 60px;
    right: 10px;
  }

  .connection-line {
    position: absolute;
    bottom: -45px;
    height: 50px;
    width: 10px;
    background-color: #adadad;
    border: solid 1px #606060;
    left: 50%;
    z-index: -1;
  }
}