* {
  box-sizing: border-box;
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;

  display: flex;
  background-color: aliceblue;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
}

img {
  position: relative;
  width: 100%;
  height: 100%;
}

.page-content-root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  /*overflow: hidden;*/
  /*height: auto;*/
}

.main-content {
  margin-bottom: 95px;
}

.f-row-fs-fs {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.f-row-fs-c {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.f-row-c-c {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.f-col-fs-fs {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.f-col-c-c {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapping-div {
  position: relative;
  width: 100%;
  height: 160px;
}

.wrapping-div > div {
  padding: 5px;
  border-radius: 5px;
}

.img-div {
  width: 160px;
  height: 160px;
}

.img-div img {
  object-fit: cover;
}