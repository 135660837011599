.financial-entry {
    width: 100%;
    margin: 20px 0;
    padding: 15px;
}

.objective-amount {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

form > div {
    margin-bottom: 1rem !important;
}