#cleaning-plan-logo {
    height: 120%;
}

.bg-page-root {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    z-index: -5;
}