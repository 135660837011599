.calendar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
@media (min-width: 760px) {
  .calendar {
    width: 760px;
  }
}