.page-root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.content-root {
  position: relative;
  width: 100%;
  padding: 0 10px;
}

.settings-panel {
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: 45px;
  .MuiPaper-root {
    padding: 10px;
    height: 100%;
  }
  .MuiButton-root {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #value {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }
}

@media (min-width: 540px) {
  .content-root {
    width: 540px;
  }
}